<template>
  <div class="row d-inline mx-0">
    <div class="card card-custom gutter-b">
      <div class="card-body p-4">
        <div
          class="d-flex justify-content-center mx-0"
          v-if="!loadingComplete && !error.active"
        >
          <b-spinner
            variant="primary"
            label="Spinning"
            class="table-loader my-3"
          >
          </b-spinner>
        </div>
        <div v-if="loadingComplete">
          <div class="d-flex justify-content-between mb-5">
            <h6 class="my-1 py-2">
              {{ $t("REVIEW.TEXT_ANALYSIS." + type.toUpperCase()) }}
            </h6>
            <button
              v-if="type == 'Base'"
              type="button"
              class="btn"
              :class="comparisonEnabled ? 'btn-primary' : 'btn-outline-primary'"
              @click="enableComparison"
            >
              {{ buttonText }}
            </button>
          </div>
          <!-- start::Activity Filter -->
          <div class="row d-flex justify-content-between px-5 py-2">
            <label
              class="col-md-3 text-right my-3 pl-0"
              for="filter-activities"
            >
              Within:
            </label>
            <treeselect
              id="filter-activities"
              :placeholder="$t('TABLES.SEGMENT_HEADERS.ACTIVITIES')"
              class="col-xl-9 px-0"
              value="id"
              label="title"
              :value-consists-of="treeSelectOptions.valueConsistsOf"
              :noChildrenText="$t('REVIEW.TEXT_ANALYSIS.NO_QUESTIONS')"
              :multiple="treeSelectOptions.multiple"
              :showCount="treeSelectOptions.showCount"
              :clearable="treeSelectOptions.clearable"
              :searchable="treeSelectOptions.searchable"
              :open-on-focus="treeSelectOptions.openOnFocus"
              :clear-on-select="treeSelectOptions.clearOnSelect"
              :close-on-select="treeSelectOptions.closeOnSelect"
              :open-on-click="treeSelectOptions.openOnClick"
              :options="formattedActivities"
              :max-height="200"
              v-model="filter.activities"
            >
              <label
                slot="option-label"
                slot-scope="{ node, labelClassName }"
                v-html="node.label"
                v-if="node.raw.isHTML"
                :class="labelClassName"
              >
              </label>
              <label
                slot="option-label"
                slot-scope="{ node, labelClassName }"
                :class="labelClassName"
                v-else
              >
                {{ node.label }}
              </label>
              <div
                slot="value-label"
                slot-scope="{ node }"
                v-html="node.label"
                v-if="node.raw.isHTML"
              ></div>
              <slot slot="value-label" slot-scope="{ node }" v-else>
                {{ node.label }}
              </slot>
            </treeselect>
          </div>
          <!-- end::Activity Filter -->
          <!-- start::Participants -->
          <div class="row d-flex justify-content-between px-5 py-2">
            <label
              class="col-md-3 text-right my-3 pl-0"
              for="filter-participants"
            >
              By:
            </label>
            <treeselect
              id="filter-participants"
              :placeholder="$t('TABLES.SEGMENT_HEADERS.PARTICIPANTS')"
              class="col-xl-9 px-0"
              :value-consists-of="treeSelectOptions.valueConsistsOf"
              :noChildrenText="$t('REVIEW.TEXT_ANALYSIS.NO_PARTICIPANTS')"
              :multiple="treeSelectOptions.multiple"
              :showCount="treeSelectOptions.showCount"
              :clearable="treeSelectOptions.clearable"
              :searchable="treeSelectOptions.searchable"
              :open-on-focus="treeSelectOptions.openOnFocus"
              :clear-on-select="treeSelectOptions.clearOnSelect"
              :close-on-select="treeSelectOptions.closeOnSelect"
              :open-on-click="treeSelectOptions.openOnClick"
              :options="projectUsers"
              :max-height="200"
              v-model="filter.participants"
            />
          </div>
          <!-- end::Participants -->
          <!-- start::Segments -->
          <div class="row d-flex justify-content-between px-5 py-2">
            <label class="col-md-3 text-right my-3 pl-0" for="filter-segments">
              In One Of:
            </label>
            <treeselect
              id="filter-segments"
              :placeholder="$t('GENERAL_WIDGETS.PROJECT_SEGMENTS.TITLE')"
              class="col-xl-9 px-0"
              :value-consists-of="treeSelectOptions.valueConsistsOf"
              :noChildrenText="$t('REVIEW.TEXT_ANALYSIS.NO_SEGMENTS')"
              :multiple="treeSelectOptions.multiple"
              :showCount="treeSelectOptions.showCount"
              :clearable="treeSelectOptions.clearable"
              :searchable="treeSelectOptions.searchable"
              :open-on-focus="treeSelectOptions.openOnFocus"
              :clear-on-select="treeSelectOptions.clearOnSelect"
              :close-on-select="treeSelectOptions.closeOnSelect"
              :open-on-click="treeSelectOptions.openOnClick"
              :options="segmentationBreakdown"
              :max-height="200"
              v-model="filter.segments"
            />
          </div>
          <!-- end::Segments -->
          <!-- start::Specific Words -->
          <div class="row d-flex justify-content-between px-5 py-2">
            <label class="col-md-3 text-right my-3 pl-0" for="filter-specific">
            </label>
            <tagify
              id="filter-specific"
              class="col-md-9 py-2 px-2"
              :tags="filter.specific_words"
              :placeholder="$t('REVIEW.TEXT_ANALYSIS.SPECIFIC_WORDS')"
            ></tagify>
          </div>
          <!-- end::Specific Words -->
          <!-- start::Excluded Words -->
          <div class="row d-flex justify-content-between px-5 py-2">
            <label class="col-md-3 text-right my-3 pl-0" for="filter-excluded">
            </label>
            <tagify
              id="filter-excluded"
              class="col-md-9 py-2 px-2"
              :tags="filter.excluded_words"
              :placeholder="$t('REVIEW.TEXT_ANALYSIS.EXCLUDED_WORDS')"
            ></tagify>
          </div>
          <!-- end::Excluded Words -->
          <!-- start::Exclude Common Words -->
          <div class="row d-flex justify-content-start px-5 pt-2">
            <label
              class="col-md-3 text-right my-3 pl-0"
              for="exclude-common-words"
            >
            </label>
            <b-form-checkbox
              v-model="filter.excludeCommonWords"
              value="1"
              unchecked-value="0"
              size="md"
            >
              <p class="pt-1 mb-0">
                {{ $t("REVIEW.TEXT_ANALYSIS.EXCLUDE_COMMON") }}
              </p>
            </b-form-checkbox>
          </div>
          <!-- end::Exclude Common Words -->
          <!-- start::Exclude My Posts -->
          <div class="row d-flex justify-content-start px-5 py-2">
            <label class="col-md-3 text-right my-3 pl-0" for="exclude-my-posts">
            </label>
            <b-form-checkbox
              v-model="filter.excludeMyPosts"
              value="1"
              unchecked-value="0"
              size="md"
            >
              <p class="pt-1">{{ $t("REVIEW.TEXT_ANALYSIS.EXCLUDE_POSTS") }}</p>
            </b-form-checkbox>
          </div>
          <!-- end::Exclude My Posts -->
          <!-- start:Filter Actions -->
          <div class="d-flex justify-content-end mb-5">
            <button
              type="button"
              class="btn btn-secondary px-7"
              @click="resetFilter"
            >
              {{ $t("REVIEW.TEXT_ANALYSIS.RESET") }}
            </button>
            <button
              type="button"
              class="btn btn-primary px-7 ml-3"
              @click="applyFilter"
            >
              {{ $t("REVIEW.TEXT_ANALYSIS.APPLY") }}
            </button>
          </div>
          <!-- end::Filter Actions -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Tagify from "tagify-vue";

export default {
  name: "TAFilter",

  props: [
    "type",
    "error",
    "pageLoader",
    "comparisonEnabled",
    "formattedActivities",
    "projectUsers",
    "segmentationBreakdown",
    "textAnalysisExclusion",
  ],

  components: { Treeselect, Tagify },

  data() {
    return {
      filter: {
        activities: [],
        participants: [],
        segments: [],
        specific_words: [],
        excluded_words: [],
        excludeCommonWords: "1",
        excludeMyPosts: "1",
        word_limit: 50,
      },
      treeSelectOptions: {
        valueConsistsOf: "LEAF_PRIORITY",
        noChildrenText: `${this.$t("REVIEW.TEXT_ANALYSIS.NO_CHILDREN")}`,
        multiple: true,
        showCount: true,
        clearable: true,
        searchable: true,
        openOnFocus: false,
        clearOnSelect: true,
        closeOnSelect: false,
        openOnClick: true,
        rtl: false,
      },
    };
  },

  mounted() {
    if (this.textAnalysisExclusion.length > 0) {
      this.addExcludedWords();
    }
  },

  methods: {
    enableComparison() {
      this.$emit("enableComparison");
    },
    addExcludedWords() {
      let newExcludedWordsArray = this.filter.excluded_words.concat(
        this.textAnalysisExclusion
      );
      const unique = (value, index, self) => {
        return self.indexOf(value) === index;
      };
      const uniqueWords = newExcludedWordsArray.filter(unique);
      this.filter.excluded_words = uniqueWords;
    },
    removeExcludedWords() {
      let newExcludedWordsArray = this.filter.excluded_words.filter(
        (el) => !this.textAnalysisExclusion.includes(el)
      );
      this.filter.excluded_words = newExcludedWordsArray;
    },
    resetFilter() {
      this.filter.activities = [];
      this.filter.participants = [];
      this.filter.segments = [];
      this.filter.specific_words = [];
      this.filter.excluded_words = this.textAnalysisExclusion;
      this.filter.excludeCommonWords = 1;
      this.filter.excludeMyPosts = 1;

      this.applyFilter();
    },
    applyFilter() {
      if (this.type == "Base") {
        this.$emit("baseFilterApplied", this.filter);
      } else {
        this.$emit("comparisonFilterApplied", this.filter);
      }
    },
    fetchInnerChildOptions(activityId, activityType) {
      let payload = {
        activityId: activityId,
        params: {
          activityType: activityType,
        },
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_QUESTION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    },
  },

  watch: {
    loadingComplete: function (newValue, oldValue) {
      if (oldValue == false && newValue == true) {
        this.addExcludedWords();
      }
    },
    "filter.excludeCommonWords": function (newValue, oldValue) {
      if (oldValue != newValue) {
        newValue == 1 ? this.addExcludedWords() : this.removeExcludedWords();
      }
    },
  },

  computed: {
    buttonText: function () {
      if (this.comparisonEnabled) {
        return `${this.$t("REVIEW.TEXT_ANALYSIS.DISABLE_COMP")}`;
      } else {
        return `${this.$t("REVIEW.TEXT_ANALYSIS.ENABLE_COMP")}`;
      }
    },
    loadingComplete: function () {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
