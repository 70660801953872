<template>
  <div :class="comparisonEnabled ? 'col-xl-6' : 'col-xl-12'" >
    <div class="card card-custom gutter-b">
      <div v-if="loadingComplete" class="card-header">
        <div class="card-title d-flex justify-content-between w-100">
          <h3 class="card-label">
            {{ $t('REVIEW.TEXT_ANALYSIS.' + type.toUpperCase()) }}
          </h3>
          <div class="card-toolbar">
            <amChartsExport
              v-if="chart != null && currentUser.relation_to_project != 'observer'"
              position="left"
              :dataTypes="['png','jpg','svg','json','csv']"
              :chart="chart"
              title="text_analysis"
            >
            </amChartsExport>
          </div>
        </div>
      </div>
      <div class="card-body p-4">
        <div class="d-flex justify-content-center mx-0" v-if="!loadingComplete && !error.active">
          <b-spinner
            variant="primary"
            label="Spinning"
            class="table-loader my-3"
          >
          </b-spinner>          
        </div>
        <div v-show="textAnalysis.length > 0" :id="'chartdiv-' + type" :class="type == 'Comparison' ? 'wordcloud active' : chartDivClass"></div>
        <div v-if="textAnalysis.length > 0 && loadingComplete" class="mt-10">
          <div class="d-flex justify-content-center">
            <input
              :id="'text_analysis_search_'+type"
              v-model="search"
              type="text"
              :placeholder="$t('GENERAL.INPUT.SEARCH')"
              class="form-control form-control-solid h-auto py-3 px-5 mb-5"
              :class="comparisonEnabled ? 'w-100' : 'w-50'"
              :disabled="loadingComplete == false"
              @keyup="searchWordcloud"
            />
          </div>
          <div class="d-flex justify-content-center">
            <div class="table-responsive" :class="comparisonEnabled ? 'w-100' : 'w-50'">
              <table
                :id="'text_analyis_table_'+type"
                class="table table-head-custom table-vertical center table-hover"
                style="table-layout:auto"
              >
                <thead>
                  <tr class="text-left">
                    <th class="px-2" style="width:100px">
                      {{ $t('TABLES.TEXT_ANALYSIS.RANK') }}
                    </th>
                    <th class="px-2" style="min-width:250px">
                      {{ $t('TABLES.TEXT_ANALYSIS.WORD') }}
                    </th>
                    <th class="px-2 text-center" style="min-width:100px">
                      {{ $t('TABLES.TEXT_ANALYSIS.FREQUENCY') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(item, i) in textAnalysis">
                    <tr
                      v-bind:key="i"
                      :class="item.word == search.toLowerCase() ? 'highlighted' : ''"
                      @click="selectRow(item.word)"
                      style="cursor:pointer"
                    >
                      <td class="px-2 py-4 d-table-cell">
                        <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                          {{ i }}
                        </span>
                      </td>
                      <td class="px-2 py-4 d-table-cell">
                        <a @click="applyStreamFilter({ search: ' ' + item.word + ' ', preLoading: 1 })">
                          <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                            {{ item.word }}
                          </span>
                        </a>
                      </td>
                      <td class="px-2 py-4 d-table-cell text-center">
                        <span class="text-dark-65 font-weight-bold d-block font-size-sm">
                          {{ item.count }}
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!--start::Placeholder for project has no responses-->
        <b-alert v-if="textAnalysis.length == 0 && !error.active && loadingComplete" show variant="light">
          {{ $t('GENERAL_WIDGETS.TEXT_ANALYSIS.NOT_READY_2') }}
        </b-alert>
        <!--end::Placeholder for project has no responses-->
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapGetters
} from "vuex";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud"; 
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import amChartsExport from "@/view/components/AMChartExport";

import { POPULATE_FILTER_LIST } from "@/core/services/store/project/project_stream.module";

export default {
  name: "ReviewTextAnalysis",

  props: [
    "type",
    "comparisonEnabled",
    "pageLoader",
    "error",
    "textAnalysis"
  ],

  data() {
    return {
      chartDivClass: "wordcloud",
      search: "",
      chart: null
    }
  },
  
  components: {
    amChartsExport
  },

  methods: {
    applyStreamFilter: function(filter) {
      this.$store
        .dispatch(POPULATE_FILTER_LIST, filter)
        .then(() => {
          this.$router.push({ name: 'stream'})
        })
    },   
    selectRow(word) {
      this.search = word;
      this.searchWordcloud();
    },
    searchWordcloud() {
      var words = document.getElementById("chartdiv-"+this.type).querySelectorAll("tspan");
      var i;
      for (i = 0; i < words.length; i++) {
        if (words[i].innerHTML == this.search.toLowerCase()) {
          words[i].classList.add("highlighted");
        } else {
          words[i].classList.remove("highlighted");
        }
      }
    },
    generateChart() {
      this.chartDivClass = "wordcloud active";
      am4core.useTheme(am4themes_animated);
      var chart = am4core.create("chartdiv-"+this.type, am4plugins_wordCloud.WordCloud);
      var series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());

      series.accuracy = 5;
      series.step = 15;
      series.angles = [0];
      series.randomness = .1
      series.maxCount = 50;
      series.labels.template.tooltipText = "{word}: {value}";
      series.fontFamily = "sans-serif";
      series.maxFontSize = (this.comparisonEnabled) ? am4core.percent(10) : am4core.percent(16);
      series.minFontSize = am4core.percent(4);
      series.heatRules.push({
          "target": series.labels.template,
          "property": "fill",
          "min": am4core.color("#748592"),
          "max": am4core.color("#18344a"),
          "dataField": "value"
      });

      series.data = this.textAnalysis;
      series.dataFields.word = "word";
      series.dataFields.value = "count";

      chart.exporting.adapter.add("data", function(data) {
        data.data = series.data
        return data;
      });

      this.chart = chart;
    },
  },

  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },

  watch: {
    loadingComplete: function(newValue, oldValue) {
      if(oldValue == false && newValue == true)
      {
        this.generateChart();
      }
    },
    textAnalysis: function(newValue, oldValue) {
      if(oldValue != newValue && this.loadingComplete)
      {
        if (this.chart) {
          this.chart.dispose();
        }
        this.generateChart();
      }
    },
    comparisonEnabled: function(newValue, oldValue) {
      if(oldValue != newValue && this.type == "Base")
      {
        if (this.chart) {
          this.chart.dispose();
        }
        this.generateChart();
      }
    }
  },

  computed: {
    ...mapGetters([
      "currentUser"
    ]),
    loadingComplete: function() {
      if (
        this.pageLoader.componentsCompleted >=
          this.pageLoader.componentsRequired &&
        this.error.active == false
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style scoped>
  .wordcloud.active {
    width: 100%;
    height: 435px;
  }
</style>