<template>
  <div class="d-flex justify-content-between">
    <div class="col-xl-3 pl-0 pr-2">
      <AnalysisFilter
        type="Base"
        :pageLoader="pageLoader"
        :error="error"
        :comparisonEnabled="comparisonEnabled"
        :formattedActivities="formattedActivities"
        :projectUsers="formattedProfiles"
        :segmentationBreakdown="segmentationBreakdown"
        :textAnalysisExclusion="textAnalysisExclusion"
        @enableComparison="toggleComparison"
        @baseFilterApplied="fetchBaseProjectTextAnalysis"
      >
      </AnalysisFilter>
      <AnalysisFilter
        type="Comparison"
        v-if="comparisonEnabled"
        :pageLoader="pageLoader"
        :error="error"
        :comparisonEnabled="comparisonEnabled"
        :formattedActivities="formattedActivities"
        :projectUsers="formattedProfiles"
        :segmentationBreakdown="segmentationBreakdown"
        :textAnalysisExclusion="textAnalysisExclusion"
        @comparisonFilterApplied="fetchComparisonTextAnalysis"
      >
      </AnalysisFilter>
    </div>
    <div class="d-flex justify-content-between col-xl-9 pl-2 pr-0">
      <ReviewTextAnalysis
        type="Base"
        :comparisonEnabled="comparisonEnabled"
        :pageLoader="pageLoader"
        :error="error"
        :textAnalysis="baseProjectTextAnalysis"
      >
      </ReviewTextAnalysis>
      <ReviewTextAnalysis
        type="Comparison"
        v-if="comparisonEnabled"
        :comparisonEnabled="comparisonEnabled"
        :pageLoader="pageLoader"
        :error="error"
        :textAnalysis="comparisonProjectTextAnalysis"
      >
      </ReviewTextAnalysis>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { POPULATE_ACTIVITY_LIST } from "@/core/services/store/activity/activity.module";
import { POPULATE_ACTIVITY_QUESTION_LIST } from "@/core/services/store/activity/activity.module";
import { POPULATE_PROJECT_PROFILES_LIST } from "@/core/services/store/project/project_profiles.module";
import { POPULATE_PROJECT_SEGMENTATION_LIST } from "@/core/services/store/project/project_segmentations.module";
import { POPULATE_PROJECT_SEGMENT_LIST } from "@/core/services/store/project/project_segments.module";
import {
  POPULATE_TEXT_ANALYSIS_SUMMARY,
  POPULATE_TEXT_ANALYSIS_EXCLUSION,
} from "@/core/services/store/project/project_text_analysis.module";

import AnalysisFilter from "@/view/pages/project_management/review/text_analysis/Filter";
import ReviewTextAnalysis from "@/view/pages/project_management/review/text_analysis/ReviewTextAnalysis";

export default {
  name: "TextAnalysis",

  components: { AnalysisFilter, ReviewTextAnalysis },

  data() {
    return {
      comparisonEnabled: 0,
      questionTreeList: {
        innerChildToFetch: 0,
        innerChildCompleted: 0,
      },
      formattedActivities: [],
      formattedProfiles: [],
      segmentationBreakdown: [],
      baseProjectTextAnalysis: [],
      comparisonProjectTextAnalysis: [],
      pageLoader: {
        componentsCompleted: 0,
        componentsRequired: 4,
      },
      error: {
        active: false,
        message: "",
      },
    };
  },

  mounted() {
    this.fetchActivitiesWithQuestions();
    this.fetchProjectProfiles();
    this.fetchProjectSegmentations();
    this.fetchProjectExcludedWords();
  },

  methods: {
    toggleComparison() {
      if (this.comparisonEnabled) {
        this.comparisonEnabled = false;
      } else {
        this.comparisonEnabled = true;
      }
    },
    fetchActivitiesWithQuestions() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false
        }
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_LIST, payload)
        .then(() => {
          for (let i = 0; i < this.activityList.length; i++) {
            let diaryOrSurvey = [2, 4];
            if (
              diaryOrSurvey.includes(
                this.activityList[i].system_activity_type_id
              )
            ) {
              this.pageLoader.componentsRequired++;
              this.questionTreeList.innerChildToFetch++;
              this.fetchInnerChildOptions(
                this.activityList[i].id,
                this.activityList[i].system_activity_type_id
              );
            }
          }
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    },
    fetchInnerChildOptions(activityId, activityType) {
      let payload = {
        activityId: activityId,
        params: {
          activityType: activityType,
          paginate: false
        },
      };
      this.$store
        .dispatch(POPULATE_ACTIVITY_QUESTION_LIST, payload)
        .then(() => {
          this.pageLoader.componentsCompleted++;
          this.questionTreeList.innerChildCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the activity list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectProfiles() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          scope: "accepted",
          user: "with",
          paginate: false
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_PROFILES_LIST, payload)
        .then(() => {
          let formattedProfiles = [];
          var i;
          for (i = 0; i < this.projectProfiles.length; i++) {
            if (this.projectProfiles[i].system_project_role_id == 1) {
              let displayName = this.authIsObserver
                ? "participant_" + this.projectProfiles[i].id
                : this.projectProfiles[i].user.display_name;
              let object = {
                id: this.projectProfiles[i].id,
                label: displayName,
              };
              formattedProfiles.push(object);
            }
          }
          this.formattedProfiles = formattedProfiles;
          this.pageLoader.componentsCompleted++;
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project users list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectSegmentations() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENTATION_LIST, payload)
        .then(() => {
          this.fetchProjectSegments();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segmentations list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectSegments() {
      let payload = {
        projectId: this.projectInfo.id,
        params: {
          paginate: false,
        },
      };
      this.$store
        .dispatch(POPULATE_PROJECT_SEGMENT_LIST, payload)
        .then(() => {
          this.mergeSegmentationsAndSegments();
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the project segments list, please check back later or contact the helpdesk";
        });
    },
    fetchProjectExcludedWords() {
      this.$store
        .dispatch(POPULATE_TEXT_ANALYSIS_EXCLUSION, this.projectInfo.id)
        .then(() => {
          this.fetchBaseProjectTextAnalysis({
            word_limit: 50,
            excluded_words: this.textAnalysisExclusion,
          });
        })
        .catch(() => {
          this.error.active = true;
          this.error.message =
            "We're having some issues retrieving the excluded words list, please check back later or contact the helpdesk";
        });
    },
    fetchBaseProjectTextAnalysis(filter) {
      let payload = {
        projectId: this.projectInfo.id,
        params: filter,
      };
      this.$store
        .dispatch(POPULATE_TEXT_ANALYSIS_SUMMARY, payload)
        .then((res) => {
          this.baseProjectTextAnalysis = res;
          this.pageLoader.componentsCompleted++;
        });
    },
    fetchComparisonTextAnalysis(filter) {
      let payload = {
        projectId: this.projectInfo.id,
        params: filter,
      };
      this.$store
        .dispatch(POPULATE_TEXT_ANALYSIS_SUMMARY, payload)
        .then((res) => {
          this.comparisonProjectTextAnalysis = res;
        });
    },
    mergeSegmentationsAndSegments() {
      let segmentationBreakdown = [];
      var i;
      for (i = 0; i < this.projectSegmentations.length; i++) {
        let segmentationId = this.projectSegmentations[i].id;
        let parentObject = {
          id: this.projectSegmentations[i].name,
          label: this.projectSegmentations[i].name,
        };
        let children = [];
        var j;
        for (j = 0; j < this.projectSegments.length; j++) {
          if (
            this.projectSegments[j].project_segmentation_id == segmentationId
          ) {
            let segmentId = this.projectSegments[j].id;
            let childObject = {
              id: segmentId,
              label: this.projectSegments[j].name,
            };
            children.push(childObject);
          }
        }
        parentObject.children = children;
        children.length == 0
          ? (parentObject.isDisabled = true)
          : (parentObject.isDisabled = false);
        segmentationBreakdown.push(parentObject);
      }
      this.segmentationBreakdown = segmentationBreakdown;
      this.pageLoader.componentsCompleted++;
    },
  },

  watch: {
    comparisonEnabled: function (newValue, oldValue) {
      if (oldValue != newValue && newValue == 1) {
        this.fetchComparisonTextAnalysis({
          word_limit: 50,
          excluded_words: this.textAnalysisExclusion,
        });
      }
    },
    "questionTreeList.innerChildCompleted": {
      handler: function (completed) {
        if (completed == this.questionTreeList.innerChildToFetch) {
          let excludedActivityType = [5, 7, 9];
          let formattedActivities = [];
          for (let i = 0; i < this.activityList.length; i++) {
            if (
              !excludedActivityType.includes(
                this.activityList[i].system_activity_type_id
              )
            ) {
              let object = {
                id: this.activityList[i].id,
                label: this.activityList[i].title,
                type: this.activityList[i].system_activity_type_id,
              };

              if (this.activityList[i].child_questions) {
                object.children = this.activityList[i].child_questions;
              }

              formattedActivities.push(object);
            }
          }
          this.formattedActivities = formattedActivities;
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters([
      "activityList",
      "projectInfo",
      "projectProfiles",
      "projectSegmentations",
      "projectSegments",
      "textAnalysisExclusion",
      "currentUser",
    ]),
    authIsObserver: function () {
      return this.currentUser.projectProfile != undefined &&
        this.currentUser.projectProfile.system_project_role_id == 2
        ? true
        : false;
    },
  },
};
</script>
